import React from 'react';
import { SubHeadline } from '../../common/Headline';
import Text from '../../common/Text';
import Button from '../../common/Button';
import styled from 'styled-components';
import { Link } from 'gatsby';
const ThankYou = require('./success.png');

const Container = styled.div`
  text-align: center;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Image = styled.img`
  max-width: 40%;
`;

export default ({
  title = 'Thank you',
  text = 'Thanks for submitting your Information, you will be notified over the next steps.',
  button = 'Go back to overview',
}) => (
  <Container>
    <Image src={ThankYou} loading='lazy' decoding='async' />
    <SubHeadline>{title}</SubHeadline>
    <Text>{text}</Text>
    <StyledLink to="/mybunac">
      <Button variant="primary" label={button} />
    </StyledLink>
  </Container>
);
