import React, { useState } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';

const FORM_ID = 'visa_assessment';
const PIPELINE_ID = 29;

const formConfig = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Date of Birth',
    name: 'Date of Birth',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label:
      'Passport Nationality (If you are a dual citizen please include both nationalities)',
    name: 'Nationality (from your passport)',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'The country that you currently live in',
    name: 'Country of Residence',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Have you ever had a working holiday visa for any country before? ',
    isNote: true,
    model: DATA_MODELS.deal,
    type: FIELD_TYPES.select,
    isRequired: true,
    options: [
      { label: 'Yes', id: 'Yes' },
      { label: 'No', id: 'No' },
    ],
  },
  {
    label: 'If yes please list the countries, and the dates of those visas',
    isNote: true,
    model: DATA_MODELS.deal,
    type: FIELD_TYPES.text,
    isRequired: true,
  },
  {
    label: 'Have you ever been arrested, cautioned or reprimanded before?',
    name: 'Arrested before?',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: (
      <>
        BUNAC takes your privacy seriously and will only use your details to
        provide you with information on which working holiday schemes and BUNAC
        programmes you are eligible for. Please check this box to agree to our{' '}
        <a href="/privacy" target="_blanc">
          <b>privacy policy</b>
        </a>
        .
      </>
    ),
    name: 'agreed to privacy',
    model: DATA_MODELS.person,
    isRequired: true,
  },
];

const VisaAssessment = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="Visa Assessment"
      formId={FORM_ID}
      pipelineId={PIPELINE_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
    />
  );
};

export default VisaAssessment;